<template>
    <div>
        <h3>Popup</h3>
        <v-btn text @click="showDialog" outlined class="btn btn-lg btn-add-main" style="background:transparent;">
            <i class="fas fa-plus-circle"></i> Add Popup
        </v-btn>

        <v-btn text :to="{ name: 'manage-websites', params: { domainname: siteUrl }}" outlined
               class="btn btn-lg btn-secondary-main" style="background:transparent;">
            <i class="fas fa-arrow-left"></i> Back
        </v-btn>
        <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
        <i class="fa fa-globe"></i>
        {{siteUrl}}
    </span>
        <v-app>
            <div class="row mt-2">
                <div class="col-xl-12">
                    <KTPortlet v-bind:title="''">
                        <template v-slot:body>
<!--                                <pre>-->
<!--                                {{popups}}-->
<!--                            </pre>-->
                            <div class="row" v-if="loading">
                                <div class="col-md-12">
                                    <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                                </div>
                            </div>
                            <div class="row mt-2" v-else-if="!loading && popups && popups.length">
                                <b-table hover responsive fixed :items="popups" id="my-table" :busy.sync="isBusy"
                                         :fields="fields" :current-page="currentPage" :per-page="perPage">
                                    <template v-slot:table-busy>
                                        <div class="text-center text-info my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong>Loading Pages...</strong>
                                        </div>
                                    </template>
                                    <template v-slot:cell(title)="data">
                                        <span v-if="data.item.title" >{{data.item.title | truncate(20, '...')}}</span>
                                        <span v-else>N/A</span>
                                    </template>
                                    <template v-slot:cell(link)="data">
                                        <span v-if="data.item.link" >{{data.item.link | truncate(20, '...')}}</span>
                                        <span v-else>N/A</span>
                                    </template>
                                    <template v-slot:cell(video_url)="data">
                                        <span v-if="data.item.video_url" >{{data.item.video_url | truncate(20, '...')}}</span>
                                        <span v-else>N/A</span>
                                    </template>
                                    <template v-slot:cell(is_active)="data">
                                        <i v-if="data.item.is_active"
                                           class="kt-nav__link-icon flaticon2-check-mark text-success"></i>

                                        <i v-else class="kt-nav__link-icon flaticon2-cancel-music text-danger"></i>
                                    </template>

                                    <!-- A virtual composite column -->
                                    <template v-slot:cell(action)="data">
                                        <div class="kt-widget__toolbar">
                                            <a href="#" class="btn btn-clean btn-sm btn-icon btn-icon-md"
                                               data-toggle="dropdown">
                                                <i class="flaticon-more-1"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                <ul class="kt-nav">
                                                    <li class="kt-nav__item">
                                                        <a href="#" @click="editPopup(data.item)" class="kt-nav__link">
                                                            <i class="kt-nav__link-icon flaticon-edit"></i>
                                                            <span class="kt-nav__link-text">Edit</span>
                                                        </a>
                                                    </li>
                                                    <li class="kt-nav__item">
                                                        <a href="#" class="kt-nav__link"
                                                           @click="deletePopup(data.item.id)">
                                                            <i class="kt-nav__link-icon flaticon-delete kt-font-danger"></i>
                                                            <span class="kt-nav__link-text">Delete</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </template>
                                </b-table>
                                <div class="col-md-12 text-right">
                                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                                                  aria-controls="my-table" class="pull-right"></b-pagination>
                                </div>
                            </div>

                          <div class="row mt-2" v-else>
                            <div class="col-md-12 text-center">
                              <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                              <h5 class="mt-10">Looks like you have not added any data here. Trying adding some data.</h5>
                            </div>
                          </div>
                        </template>
                    </KTPortlet>
                </div>
            </div>
            <v-dialog scrollable v-model="dialog" persistent max-width="900px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Add Popup</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <div class="form-block">
                                <v-row>
                                    <v-col cols="6" md="6">
                                        <v-col cols="12" md="12">
                                            <v-text-field label="Title" v-model="popup.title" required dense
                                                          outlined></v-text-field>
                                            <p class="text-danger font-weight-bold" v-if="$v.popup.title.$error">Title
                                                is required</p>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <v-select :items="popupLocation" item-text="title" v-model="popup.location"
                                                      @change="chooseLocation(popup.location)" item-value="value"
                                                      label="Display Location" outlined dense></v-select>
                                        </v-col>
                                        <v-col cols="12" md="12" v-if="showOption">
                                            <v-select :items="options" item-text="title" v-model="popup.show_location"
                                                      @change="chooseOptions(popup.show_location)" item-value="value"
                                                      label="Select Location" outlined dense></v-select>
                                        </v-col>
                                        <v-col cols="12" md="12" v-if="popup.show_location=='page'">
                                            <v-select :items="pages" item-text="title" v-model="popup.page_id"
                                                      item-value="id" label="Select a Page" outlined dense></v-select>
                                        </v-col>
                                        <v-col cols="12" md="12" v-if="popup.show_location=='blog'">
                                            <v-select :items="blogs" item-text="title" v-model="popup.blog_id"
                                                      item-value="id" label="Select a Blog" outlined dense></v-select>
                                        </v-col>
                                        <v-col cols="12" md="12" v-if="popup.show_location=='blog_category'">
                                            <v-select :items="blogCategories" item-text="title"
                                                      v-model="popup.blog_category_id" item-value="id"
                                                      label="Select a Blog Category" outlined dense></v-select>
                                        </v-col>
                                        <v-col cols="12" md="12" v-if="popup.show_location=='catalogs'">
                                            <v-select :items="catalogs" item-text="label" v-model="popup.catalog_id"
                                                      item-value="id" label="Select a Catalog" outlined
                                                      dense></v-select>
                                        </v-col>
                                        <v-col cols="12" md="12" v-if="popup.show_location=='product'">
                                            <v-select :items="products" item-text="title" v-model="popup.product_id"
                                                      item-value="id" label="Select a Product" outlined
                                                      dense></v-select>
                                        </v-col>

                                        <v-col cols="12" md="12">
                                            <v-text-field outlined dense label="Link"
                                                          v-model="popup.link"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <v-text-field outlined dense label="Video Url"
                                                          v-model="popup.video_url"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <v-textarea outlined dense label="Description" v-model="popup.description"
                                                        required></v-textarea>

                                        </v-col>
                                        <v-col cols="12">
                                            <v-file-input outlined :rules="rules" v-model="popup.image" dense show-size prepend-icon="" prepend-inner-icon="mdi-camera"
                                                          counter label="Image"></v-file-input>
                                        </v-col>

                                    </v-col>
                                    <v-col cols="6" md="6">
                                        <v-col cols="12 ">
                                            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                                    transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-model="popup.start_date" label="Start date"
                                                                  outlined dense prepend-inner-icon="event" readonly
                                                                  v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="popup.start_date"
                                                               @input="menu2 = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                                                    transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-model="popup.end_date" label="End date"
                                                                  outlined dense prepend-inner-icon="event" readonly
                                                                  v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="popup.end_date"
                                                               @input="menu2 = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
<!--                                        <v-col cols="12" md="12">-->
<!--                                            <v-radio-group v-model="popup.layout" row>-->
<!--                                                <v-radio :value="'layout1'">-->
<!--                                                    <template v-slot:label>-->
<!--                                                        <v-card outlined class="mx-auto" max-width="200">-->
<!--                                                            <v-card-title>Layout 1</v-card-title>-->
<!--                                                        </v-card>-->
<!--                                                    </template>-->
<!--                                                </v-radio>-->
<!--                                                <v-radio :value="'layout2'">-->
<!--                                                    <template v-slot:label>-->
<!--                                                        <v-card outlined class="mx-auto" max-width="200">-->
<!--                                                            <v-card-title>Layout 2</v-card-title>-->
<!--                                                        </v-card>-->
<!--                                                    </template>-->
<!--                                                </v-radio>-->
<!--                                                <v-radio :value="'layout3'">-->
<!--                                                    <template v-slot:label>-->
<!--                                                        <v-card outlined class="mx-auto" max-width="200">-->
<!--                                                            <v-card-title>Layout 3</v-card-title>-->
<!--                                                        </v-card>-->
<!--                                                    </template>-->
<!--                                                </v-radio>-->
<!--                                            </v-radio-group>-->
<!--                                        </v-col>-->
<!--                                        <v-col cols="12" md="12">-->
<!--                                            <v-switch v-model="popup.show_once"-->
<!--                                                      label="Hide popup on second visit"></v-switch>-->
<!--                                        </v-col>-->
                                        <v-col cols="12" md="12">
                                            <v-switch v-model="popup.show_media_only"
                                                      label="Show Media Only"></v-switch>
                                        </v-col>
<!--                                        <v-col cols="12" md="12">-->
<!--                                            <v-switch v-model="popup.hide_title"-->
<!--                                                      label="Show Media Only"></v-switch>-->
<!--                                        </v-col>-->
                                        <v-col cols="12" md="12">
                                            <v-switch v-model="popup.is_active" label="Active"></v-switch>
                                        </v-col>
                                    </v-col>


                                </v-row>
                            </div>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
                        <v-btn color="white--text v-btn theme--light elevation-2 v-size--large primary"
                               class="btn-save-popup" @click.prevent="savePopup" :loading="isBusy">{{ edit ?
                            'Update' : 'Save' }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-app>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import {required} from "vuelidate/lib/validators";
    import WebsitePopup from "@/services/Websites/WebsitePopupService";
    import WebsitePage from "@/services/Websites/WebsitePageService";
    import WebsiteMenu from "@/services/Websites/WebsiteMenuService";
    import WebsiteMenuItem from "@/services/Websites/WebsiteMenuItemService";
    import WebsiteBlogCategory from "@/services/Websites/WebsiteBlogCategoryService";
    import WebsiteBlog from "@/services/Websites/WebsiteBlogService";
    import ProductCatalogRootService from "@/services/store/ProductCatalogRootService";
    import ProductService from "@/services/store/ProductService";

    const WebsitePopupService = new WebsitePopup();

    const WebsitePageService = new WebsitePage();

    const WebsiteMenuService = new WebsiteMenu();

    const WebsiteMenuItemService = new WebsiteMenuItem();

    const WebsiteBlogCategoryService = new WebsiteBlogCategory();

    const WebsiteBlogService = new WebsiteBlog();

    const rootCatalogService = new ProductCatalogRootService();

    const Product = new ProductService();
    export default {
        name: "website-pages",
        components: {
            KTPortlet
        },

        validations() {
            return {
                popup: {
                    title: {
                        required
                    }
                }
            };
        },

        data() {
            return {
                isBusy: false,
                loading: true,
                dialog: false,
                menu2: false,
                menu3: false,
                showOption: false,
                selectedLocation: null,
                option: null,

                popupLocation: [{
                    title: "Home page",
                    value: "homepage"
                },
                    {
                        title: "Specific page, post or product",
                        value: "specific"
                    },
                    {
                        title: "Everywhere through the website",
                        value: "all"
                    },
                ],
                options: [{
                    title: "Display on a page",
                    value: "page"
                },
                    {
                        title: "Display on a blog post",
                        value: "blog"
                    },
                    {
                        title: "Display on a blog category",
                        value: "blog_category"
                    },
                    {
                        title: "Display on a product catalog",
                        value: "catalogs"
                    },
                    {
                        title: "Display on a product",
                        value: "product"
                    }
                ],
                rules: [
                    value =>
                        !value ||
                        value.size < 2000000 ||
                        "Image size should be less than 2 MB!"
                ],
                fields: [{
                    key: "title",
                    sortable: true
                },
                    {
                        key: "link",
                        label: "Link",
                        sortable: false
                    }, {
                        key: "video_url",
                        label: "Video ",
                        sortable: false
                    },
                    {
                        key: "start_date",
                        label: "Starts from",
                        sortable: false
                    },
                    {
                        key: "end_date",
                        label: "Ends on",
                        sortable: false
                    },
                    {
                        key: "is_active",
                        label: "Published ",
                        sortable: false
                    },
                    {
                        key: "action",
                        sortable: false
                    }
                ],
                edit: false,
                popup: {
                    title: null,
                    description: null,
                    location: null,
                    show_location: null,
                    link: null,
                    page_id: null,
                    blog_id: null,
                    blog_category_id: null,
                    product_id: null,
                    catalog_id: null,
                    image: null,
                    video_url: null,
                    show_media_only: null,
                    layout:'layout1',
                    hide_title: null,
                    start_date: null,
                    end_date: null,
                    show_once: 0,
                    is_active: 1
                },
                popups: [],
                blogs: [],
                blogCategories: [],
                catalogs: [],
                pages: [],
                products: [],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [5, 10, 15]
            };
        },
        methods: {
            showDialog() {
                this.dialog = true;
            },

            closeDialog() {
                this.dialog = false;
                this.$v.$reset();
            },
            editPopup(item) {
                this.popup = item;
                this.popup.image = null;
                this.edit = true;
                this.showDialog();
            },
            deletePopup(id) {
                this.$confirm(
                    {
                        message: `Are you sure you want to delete this popup?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                WebsitePopupService.delete(this.siteUrl, id).then(res => {
                                    this.$snotify.success("Popup deleted");
                                    this.getPopups();
                                });
                            }
                        }
                    }
                )

            },
            getPopups() {
                WebsitePopupService.paginate(this.siteUrl)
                    .then(res => {
                        this.popups = res.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                        this.loading = false;
                    });
            },

            getProducts() {
                Product.paginate({}).then(res => {
                    this.products = res.data.result;
                });
            },
            getCatalogs() {
                rootCatalogService
                    .getAll()
                    .then(response => {
                        if (response.data.length > 0) {
                            response.data.forEach(ele => {
                                ele.catalogs.forEach(e => {
                                    this.catalogs.push(e);
                                    if (e.children.length > 0) {
                                        e.children.forEach(child => {
                                            this.catalogs.push(child);
                                        });
                                    }
                                });
                            });
                        }
                    })
                    .catch(error => {
                    });
            },

            //get all
            getBlogs() {
                WebsiteBlogService.paginate(this.siteUrl).then(res => {
                    this.blogs = res.data.blogs;
                    // this.pages = res.data.data;
                });
            },
            chooseLocation(location) {
                if (location == "specific") {
                    this.showOption = true;
                    this.popup.location = location;
                } else {
                    this.showOption = false;
                    this.popup.location = location;
                }
            },
            chooseOptions(location) {
                this.popup.show_location = location;
            },
            getBlogCategory() {
                WebsiteBlogCategoryService.paginate(this.siteUrl).then(res => {
                    this.blogCategories = res.data.data;
                    // this.pages = res.data.data;
                });
            },
            getPages(type = "default", flag = null) {
                WebsitePageService.paginate(this.siteUrl, type, flag)
                    .then(res => {
                        this.pages = res.data.pages;
                    })
                    .catch(err => {
                    });
            },

            savePopup() {
                this.$v.$touch();
                if (!this.$v.popup.$error) {
                    if (this.edit) {
                        this.updatePopup();
                    } else this.createPopUp();
                }
            },
            updatePopup() {
                this.isBusy = true;
                let data = {};
                data = new FormData();
                for (var key in this.popup) {
                    if (
                        key === "page_id" &&
                        (this.popup["page_id"] == null ||
                            this.popup["page_id"] == undefined)
                    ) {
                    } else if (
                        key === "blog_id" &&
                        (this.popup["blog_id"] == null ||
                            this.popup["blog_id"] == undefined)
                    ) {
                    } else if (
                        key === "blog_category_id" &&
                        (this.popup["blog_category_id"] == null ||
                            this.popup["blog_category_id"] == undefined)
                    ) {
                    } else if (
                        key === "catalog_id" &&
                        (this.popup["catalog_id"] == null ||
                            this.popup["catalog_id"] == undefined)
                    ) {
                    } else if (
                        key === "location" &&
                        (this.popup["location"] == null ||
                            this.popup["location"] == undefined)
                    ) {
                    } else if (
                        key === "link" &&
                        (this.popup["link"] == null ||
                            this.popup["link"] == undefined)
                    ) {
                    } else if (
                        key === "video_url" &&
                        (this.popup["video_url"] == null ||
                            this.popup["video_url"] == undefined)
                    ) {
                    } else if (
                        key === "image" &&
                        (this.popup["image"] == null ||
                            this.popup["image"] == undefined)
                    ) {
                    } else if (
                        key === "description" &&
                        (this.popup["description"] == null ||
                            this.popup["description"] == undefined)
                    ) {
                    } else if (
                        key === "is_active" &&
                        (this.popup["is_active"] == 1)
                    ) {
                        data.append('is_active', 1)
                    } else if (
                        key === "show_media_only" &&
                        (this.popup["show_media_only"] == 1)
                    ) {
                        data.append('show_media_only', 1)
                    } else {
                        data.append(key, this.popup[key]);
                    }

                }
                WebsitePopupService.update(this.siteUrl, this.popup.id, data)
                    .then(res => {
                        this.$snotify.success("Notice updated");
                        this.reset();
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            },
            reset() {
                this.dialog = false;
                this.isBusy = false;
                this.edit = false;
                this.popup = {};
                this.$v.$reset();
                this.getPopups();
            },
            createPopUp() {
                this.isBusy = true;
                let data = {};
                if (this.popup.image == null) {
                    data = this.popup;
                } else {
                    data = new FormData();
                    for (var key in this.popup) {
                        if (
                            key === "page_id" &&
                            (this.popup["page_id"] == null ||
                                this.popup["page_id"] == undefined)
                        ) {
                        } else if (
                            key === "blog_id" &&
                            (this.popup["blog_id"] == null ||
                                this.popup["blog_id"] == undefined)
                        ) {
                        } else if (
                            key === "blog_category_id" &&
                            (this.popup["blog_category_id"] == null ||
                                this.popup["blog_category_id"] == undefined)
                        ) {
                        } else if (
                            key === "catalog_id" &&
                            (this.popup["catalog_id"] == null ||
                                this.popup["catalog_id"] == undefined)
                        ) {
                        } else if (
                            key === "location" &&
                            (this.popup["location"] == null ||
                                this.popup["location"] == undefined)
                        ) {
                        } else if (
                            key === "link" &&
                            (this.popup["link"] == null ||
                                this.popup["link"] == undefined)
                        ) {
                        } else if (
                            key === "video_url" &&
                            (this.popup["video_url"] == null ||
                                this.popup["video_url"] == undefined)
                        ) {
                        } else if (
                            key === "image" &&
                            (this.popup["image"] == null ||
                                this.popup["image"] == undefined)
                        ) {
                        } else if (
                            key === "description" &&
                            (this.popup["description"] == null ||
                                this.popup["description"] == undefined)
                        ) {
                        } else if (
                            key === "is_active" &&
                            (this.popup["is_active"] == 1)
                        ) {
                            data.append('is_active', 1)
                        } else if (
                            key === "show_media_only" &&
                            (this.popup["show_media_only"] == 1)
                        ) {
                            data.append('show_media_only', 1)
                        } else {
                            data.append(key, this.popup[key]);
                        }
                    }
                }

                WebsitePopupService.create(this.siteUrl, data)
                    .then(res => {
                        this.$snotify.success("Popup added");
                        this.reset();
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            },

            toggleBusy() {
                this.isBusy = !this.isBusy;
            }
        },

        computed: {
            rows() {
                return this.popups.length;
            },
            siteUrl() {
                return this.$route.params.domainname;
            },
            generateSlug() {
                return this.slugify(this.page.title);
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{
                title: "Manage Pages",
                route: "/websites"
            }]);
            this.getPopups();
            this.getPages();
            this.getBlogs();
            this.getBlogCategory();
            this.getCatalogs();
            this.getProducts();
        }
    };
</script>

<style scoped>
    #preview {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #preview img {
        max-width: 100%;
        max-height: 350px;
    }
</style>
