import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class WebsiteMenuService {

    paginate(domain_name, id, index = null) {
        let url = API_URL + 'user/website/' + domain_name + '/menu/' + id + '/item';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }

    create(domain_name, data) {
        let url = API_URL + 'user/website/' + domain_name + '/menu-item/store'
        return apiService.post(url, data);
    }

    update(domain_name, data) {
        let menuId = data.menu_id;
        let url = API_URL + 'user/website/' + domain_name + '/menu/' + menuId + '/item/' + data.id
        return apiService.put(url, data);
    }

    delete(domain_name, menuId, id) {
        let url = API_URL + 'user/website/' + domain_name + '/menu/' + menuId + '/item/' + id
        return apiService.delete(url);
    }

    sort(domain_name, menuId, data) {
        let url = API_URL + 'user/website/' + domain_name + '/menu/' + menuId + '/item/sortable';
        return apiService.post(url, data);
    }

    getMenuItems(domain_name) {
        let url = API_URL + 'user/website/' + domain_name + '/menu-item';
        return apiService.get(url);
    }

    restore(domain_name, menuId, id){
        let url = API_URL + 'user/website/' + domain_name + '/menu/' + menuId + '/item/' + id +'/restore';
        return apiService.get(url);
    }
}
